<script>
import feather from "feather-icons";
import FooterCopyright from "./FooterCopyright.vue";
import { socialLinks } from "../../data/socialLinks";

export default {
  components: { FooterCopyright },
  data() {
    return {
      socials: socialLinks,
    };
  },
  mounted() {
    feather.replace();
  },
  updated() {
    feather.replace();
  },
};
</script>

<template>
  <div class="container mx-auto">
    <div>
      <!-- Footer social links -->
      <div class="flex flex-col justify-center items-center mb-12 sm:mb-20">
        <!-- <p
          class="font-general-semibold text-3xl sm:text-4xl font-semibold text-primary-dark dark:text-primary-light mb-5"
        >
          Follow me
        </p> -->

        <div class="grid grid-cols-8 sm:grid-cols-8 gap-2">
          <img
            v-for="i in 8"
            :key="i"
            class="w-20 cursor-pointer"
            :src="require(`@/assets/images/bank/Untitled-1-17-${i}.png`)"
            alt="Transistor"
          />
        </div>

        <div class="grid grid-cols-6 sm:grid-cols-6 gap-2 pt-2">
          <img
            v-for="i in 6"
            :key="i"
            class="w-20 cursor-pointer"
            :src="require(`@/assets/images/bank/Untitled-1-17-${i + 8}.png`)"
            alt="Transistor"
          />
        </div>

        <div class="grid grid-cols-2 sm:grid-cols-2 gap-2 pt-2">
          <img
            v-for="i in 2"
            :key="i"
            class="w-20 cursor-pointer"
            :src="require(`@/assets/images/bank/Untitled-1-17-${i + 15}.png`)"
            alt="Transistor"
          />
        </div>
      </div>

      <!-- Footer copyright -->
      <FooterCopyright />
    </div>
  </div>
</template>

<style scoped></style>
