<script>
export default {
  name: "Promotion",

  components: {},
  methods: {},
};
</script>

<template>
  <section class="mx-auto my-auto lg:container">
    <!-- <img
        class="col-span-2 w-full lg:col-span-1"
        :src="require(`@/assets/images/promotion/1301866.jpg`)"
        alt="Promotion1"
      /> -->
    <img
      class="col-span-2 w-full lg:col-span-1 mb-4 fade-in"
      :src="require(`@/assets/images/promotion/1303298.jpg`)"
      alt="Promotion1"
    />
    <img
      class="col-span-2 w-full lg:col-span-1 mb-4 fade-in"
      :src="require(`@/assets/images/promotion/1303299.jpg`)"
      alt="Promotion2"
    />
    <img
      class="col-span-2 w-full lg:col-span-1 mb-4 fade-in"
      :src="require(`@/assets/images/promotion/1303300.jpg`)"
      alt="Promotion3"
    />
    <img
      class="col-span-2 w-full lg:col-span-1 mb-4 fade-in"
      :src="require(`@/assets/images/promotion/1303301.jpg`)"
      alt="Promotion4"
    />
  </section>
</template>

<style scoped>
.fade-in {
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
