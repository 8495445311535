<script>
export default {
  name: "Guide",

  components: {},
  data: () => {
    return {
      navActive: ["active", "", ""],
      currentPage: [true, false, false],
    };
  },
  methods: {
    showPage(index) {
      this.navActive = ["", "", ""];
      this.currentPage = [false, false, false];
      this.navActive[index] = "active";
      this.currentPage[index] = true;
    },
  },
  created() {
    this.bottom = document.body.scrollHeight;
    window.scrollTo(0, this.bottom);
  },
};
</script>

<template>
  <div class="bg-tabs-rec">
    <nav class="nav nav-tabs">
      <a
        :class="['nav-link', navActive[0]]"
        aria-current="page"
        @click="showPage(0)"
        >วิธีการสมัคร</a
      >
      <a
        :class="['nav-link m-4 pr-4 pl-4', navActive[1]]"
        style="border-left: 1px solid #ffe7c2; border-right: 1px solid #ffe7c2"
        @click="showPage(1)"
        >วิธีการฝากเงิน</a
      >
      <a :class="['nav-link', navActive[2]]" @click="showPage(2)"
        >วิธีการถอนเงิน</a
      >
    </nav>
  </div>

  <div class="py-24 sm:py-12">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <dl
        :class="[
          'fade-in  grid grid-cols-1 gap-y-16 gap-x-8 text-center lg:grid-cols-3',
        ]"
        v-if="currentPage[0]"
      >
        <div class="mx-auto flex max-w-xs flex-col gap-y-4">
          <img
            class="col-span-2 w-full h-32 object-contain lg:col-span-1"
            :src="require(`@/assets/images/button/button-1.png`)"
            alt="Transistor"
          />
          <div>
            <dd
              class="text-black bg-gold text-3xl font-semibold tracking-tight text-white sm:text-3xl"
            >
              สมัครสมาชิก
            </dd>
            <div class="border-glow" />

            <dt class="text-base leading-7 text-white">
              กรอกเบอร์โทรศัพท์มือถือ ของคุณให้ถูกต้อง
            </dt>
          </div>
        </div>
        <div class="mx-auto flex max-w-xs flex-col gap-y-4">
          <img
            class="col-span-2 w-full h-32 object-contain lg:col-span-1"
            :src="require(`@/assets/images/button/button-2.png`)"
            alt="Transistor"
          />
          <div>
            <dd
              class="text-black bg-gold text-3xl font-semibold tracking-tight text-white sm:text-3xl"
            >
              รอรับ SMS ยืนยัน
            </dd>
            <div class="border-glow" />

            <dt class="text-base leading-7 text-white">
              กรอก OTP ให้ถุกต้อง พร้อมตั้งรหัสเข้าเล่น
            </dt>
          </div>
        </div>
        <div class="mx-auto flex max-w-xs flex-col gap-y-4">
          <img
            class="col-span-2 w-full h-32 object-contain lg:col-span-1"
            :src="require(`@/assets/images/button/button-3.png`)"
            alt="Transistor"
          />
          <div>
            <dd
              class="text-black bg-gold text-3xl font-semibold tracking-tight text-white sm:text-3xl"
            >
              ใส่เลขบัญชีและชื่อบัญชี
            </dd>
            <div class="border-glow" />

            <dt class="text-base leading-7 text-white">
              กรอกเลขบัญชีของคุณพร้อมชื่อให้ถูกต้อง
            </dt>
          </div>
        </div>
      </dl>

      <dl
        :class="[
          'fade-in  grid grid-cols-1 gap-y-16 gap-x-8 text-center lg:grid-cols-3',
        ]"
        v-if="currentPage[1]"
      >
        <div class="mx-auto flex max-w-xs flex-col gap-y-4">
          <img
            class="col-span-2 w-full h-32 object-contain lg:col-span-1"
            :src="require(`@/assets/images/button/button-4.png`)"
            alt="Transistor"
          />
          <div>
            <dd
              class="text-black bg-gold text-3xl font-semibold tracking-tight text-white sm:text-3xl"
            >
              กด "ฝากเงิน"
            </dd>
            <div class="border-glow" />

            <dt class="text-base leading-7 text-white">
              "กรอกจำนวนเงิน" กด "ต้องการรับโปรโมชั่น" เพื่อรับโบนัสที่ต้องการ
              และกด "ยืนยัน"
            </dt>
          </div>
        </div>
        <div class="mx-auto flex max-w-xs flex-col gap-y-4">
          <img
            class="col-span-2 w-full h-32 object-contain lg:col-span-1"
            :src="require(`@/assets/images/button/button-5.png`)"
            alt="Transistor"
          />
          <div>
            <dd
              class="text-black bg-gold text-3xl font-semibold tracking-tight text-white sm:text-3xl"
            >
              โอนเงิน
            </dd>
            <div class="border-glow" />

            <dt class="text-base leading-7 text-white">
              สามารถ Scan QR Code เพื่อโอน หรือกด "คัดลอก" เลขบัญชี
              เพื่อโอนได้เช่นกัน
            </dt>
          </div>
        </div>
        <div class="mx-auto flex max-w-xs flex-col gap-y-4">
          <img
            class="col-span-2 w-full h-32 object-contain lg:col-span-1"
            :src="require(`@/assets/images/button/button-6.png`)"
            alt="Transistor"
          />
          <div>
            <dd
              class="text-black bg-gold text-3xl font-semibold tracking-tight text-white sm:text-3xl"
            >
              กด "โอนแล้ว"
            </dd>
            <div class="border-glow" />

            <dt class="text-base leading-7 text-white">
              ส่งรายการฝาก ระบบจะตรวจสอบเงิน และ เติมเงินทันที
            </dt>
          </div>
        </div>
      </dl>

      <dl
        :class="[
          'fade-in  grid grid-cols-1 gap-y-16 gap-x-8 text-center lg:grid-cols-2',
        ]"
        v-if="currentPage[2]"
      >
        <div class="mx-auto flex max-w-xs flex-col gap-y-4">
          <img
            class="col-span-2 w-full h-32 object-contain lg:col-span-1"
            :src="require(`@/assets/images/button/button-7.png`)"
            alt="Transistor"
          />
          <div>
            <dd
              class="text-black bg-gold text-3xl font-semibold tracking-tight text-white sm:text-3xl"
            >
              กด "ถอนเงิน"
            </dd>
            <div class="border-glow" />

            <dt class="text-base leading-7 text-white">
              "กรอกจำนวนเงิน ถอน" และกด "ยืนยัน"
            </dt>
          </div>
        </div>
        <div class="mx-auto flex max-w-xs flex-col gap-y-4">
          <img
            class="col-span-2 w-full h-32 object-contain lg:col-span-1"
            :src="require(`@/assets/images/button/button-8.png`)"
            alt="Transistor"
          />
          <div>
            <dd
              class="text-black bg-gold text-3xl font-semibold tracking-tight text-white sm:text-3xl"
            >
              ยืนยัน ! จบวิ๊ง
            </dd>
            <div class="border-glow" />

            <dt class="text-base leading-7 text-white">
              รอเงินเข้าภายใน 3 - 10 นาที
            </dt>
          </div>
        </div>
      </dl>
    </div>
  </div>
</template>

<style scoped>
.bg-tabs-rec {
  background: #002e12;
  padding: 10px;
  max-width: 400px;
  margin: auto;
  border-radius: 50px;
  border: 1.5px solid #ffe7c2;
}
.nav-tabs {
  border-bottom: 0px solid #dee2e6 !important;
  color: white;
}
.nav-link {
  border: 0px solid transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-link.active,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  background-color: #fff0;
  color: #fbc672;
}
a {
  cursor: pointer;
}

.fade-in {
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
