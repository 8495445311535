<script>
export default {
  name: "Brand",

  components: {},
  methods: {
    imageIndex(val, index) {
      var imageNumber = val + index;
      if (imageNumber.toString().length != 2) {
        imageNumber = "0" + imageNumber;
      }

      return imageNumber;
    },
  },
};
</script>

<template>
  <section class="mx-auto">
    <div class="sm:py-2">
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 class="text-center text-lg font-semibold leading-8 text-gray-900">
          <img
            class="col-span-2 max-h-24 w-full object-contain lg:col-span-1"
            src="@/assets/images/etc/Untitled-1-09.png"
            alt="Transistor"
          />
        </h2>
        <div
          class="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-y-2 sm:max-w-xl grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5"
        >
          <img
            v-for="i in 10"
            :key="i"
            class="col-span-2 w-full object-contain lg:col-span-1"
            :src="
              require(`@/assets/images/etc/Untitled-1-${imageIndex(i, 9)}.png`)
            "
            alt="Transistor"
            width="158"
            height="48"
          />
        </div>
      </div>

      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 class="text-center text-lg font-semibold leading-8 text-gray-900">
          <img
            class="col-span-2 max-h-24 w-full object-contain lg:col-span-1"
            src="@/assets/images/slot/slot-58.png"
            alt="Transistor"
          />
        </h2>
        <div
          class="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-y-2 sm:max-w-xl grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5"
        >
          <img
            v-for="i in 56"
            :key="i"
            class="col-span-2 w-full object-contain lg:col-span-1"
            :src="require(`@/assets/images/slot/slot-${imageIndex(i, 1)}.png`)"
            alt="Transistor"
            width="158"
            height="48"
          />
        </div>
      </div>

      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 class="text-center text-lg font-semibold leading-8 text-gray-900">
          <img
            class="col-span-2 max-h-24 w-full object-contain lg:col-span-1"
            src="@/assets/images/etc/Untitled-1-01.png"
            alt="Transistor"
          />
        </h2>
        <div
          class="mx-auto text-center mt-10 grid max-w-lg grid-cols-4 items-center gap-y-2 sm:max-w-xl grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5"
        >
          <img
            v-for="i in 4"
            :key="i"
            class="col-span-2 w-full object-contain lg:col-span-1"
            :src="
              require(`@/assets/images/etc/Untitled-1-${imageIndex(i, 1)}.png`)
            "
            alt="Transistor"
            width="158"
            height="48"
          />
        </div>
      </div>

      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 class="text-center text-lg font-semibold leading-8 text-gray-900">
          <img
            class="col-span-2 max-h-24 w-full object-contain lg:col-span-1"
            src="@/assets/images/etc/Untitled-1-06.png"
            alt="Transistor"
          />
        </h2>
        <div
          class="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-y-2 sm:max-w-xl grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5"
        >
          <img
            v-for="i in 2"
            :key="i"
            class="col-span-2 w-full object-contain lg:col-span-1"
            :src="
              require(`@/assets/images/etc/Untitled-1-${imageIndex(i, 6)}.png`)
            "
            alt="Transistor"
            width="158"
            height="48"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
/* img {
  image-resolution: from-image 300dpi;
} */
</style>
