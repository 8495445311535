<script>
import feather from "feather-icons";
import AppHeader from "./components/shared/AppHeader";
import AppFooter from "./components/shared/AppFooter";
import Home from "@/views/Home.vue";

export default {
  components: {
    AppHeader,
    AppFooter,
    Home,
  },
  data: () => {
    return {
      appTheme: "dark",
      path: "",
    };
  },
  mounted() {
    feather.replace();
  },
  updated() {
    feather.replace();
  },
};
</script>

<template>
  <div :class="appTheme" class="pt-0.5">
    <div class="bg-main"></div>
    <button class="line-contact">
      <img src="@/assets/images/line-logo-circle.png" width="50px" />
    </button>

    <div class="backwrap gradient">
      <div class="back-shapes">
        <!-- All this img tags was autogenerated by custom written js tool -->

        <span
          class="floating triangle"
          style="
            top: 1.256384065372828%;
            left: 76.92708333333333%;
            animation-delay: -0.65s;
            width: 180px;
            height: 180px;
          "
        ></span>
        <span
          class="floating triangle"
          style="
            top: 6.10599591419816%;
            left: 38.90625%;
            animation-delay: -0.35s;
            width: 50px;
            height: 50px;
          "
        ></span>
        <span
          class="floating cross"
          style="
            top: -3.0729315628192032%;
            left: 17.635416666666668%;
            animation-delay: -4.3s;
            width: 300px;
            height: 300px;
          "
        ></span>
        <span
          class="floating cross"
          style="top: 3.575076608784474%; left: 6.25%; animation-delay: -4.05s"
        ></span>
        <span
          class="floating cross"
          style="
            top: 4.3237997957099%;
            left: 4.583333333333333%;
            animation-delay: -3.75s;
            width: 30px;
            height: 30px;
          "
        ></span>
        <span
          class="floating cross"
          style="
            top: 0.9193054136874361%;
            left: 71.14583333333333%;
            animation-delay: -3.3s;
            width: 100px;
            height: 100px;
          "
        ></span>
      </div>
    </div>
    <!-- <ul class="circles">
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul> -->
    <!-- App header -->
    <AppHeader />
    <Home />
    <!-- Render active component contents with vue transition -->
    <transition name="fade" mode="out-in">
      <router-view :theme="appTheme" />
    </transition>

    <!-- Scroll to top -->
    <back-to-top
      visibleoffset="500"
      right="30px"
      bottom="20px"
      class="shadow-lg"
    >
      <i data-feather="chevron-up"></i>
    </back-to-top>

    <!-- App footer -->
    <div class="bg-black"><AppFooter /></div>
  </div>
</template>

<style>
#app {
  font-family: "IBM Plex Sans Thai", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
.bg-gold {
  color: #000 !important;
  background: rgb(181, 123, 38);
  background: linear-gradient(
    90deg,
    rgba(181, 123, 38, 1) 0%,
    rgba(224, 189, 73, 1) 35%,
    rgba(224, 189, 73, 1) 65%,
    rgba(181, 123, 38, 1) 100%
  );
  padding: 6px 10px 4px 10px;
  border-radius: 40px;
}

.bg-main {
  background: url("@/assets/images/bg-top.png") center center;
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: -3;
}

.vue-back-to-top {
  @apply p-2  text-white transition
        duration-500
        ease-in-out
        transform
        hover:-translate-y-1 hover:scale-110;
  border-radius: 50%;
  background: linear-gradient(#ca9541, #f8d88b, #ca9541);

  font-size: 22px;
  line-height: 22px;
}

.line-contact {
  @apply p-1 text-white transition
        duration-500
        ease-in-out
        transform
        hover:-translate-y-1 hover:scale-110;
  border-radius: 50%;
  background: linear-gradient(#ca9541, #f8d88b, #ca9541);
  display: flex;
  position: fixed;
  right: 12px;
  top: 240px;
  z-index: 10;
}
.bottom-button {
  position: fixed;
  z-index: 99999999;
  width: 100%;
  border-top: 1px solid #edb95638;
  bottom: 0;
  background: linear-gradient(#ca9541, #f8d88b, #ca9541);
}
.fade-enter-active {
  animation: coming 0.4s;
  animation-delay: 0.2s;
  opacity: 0;
}

.fade-leave-active {
  animation: going 0.4s;
}

@keyframes going {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-10px);
    opacity: 0;
  }
}

@keyframes coming {
  from {
    transform: translateX(-10px);
    opacity: 0;
  }

  to {
    transform: translateX(0px);
    opacity: 1;
  }
}
</style>
