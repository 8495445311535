<script>
export default {
  name: "Home",
  mounted() {
    this.path = window.location.pathname;
  },
  data: () => {
    return { path: "/" };
  },

  components: {},
  methods: {
    next(val) {
      this.$router
        .push({
          path: `/${val}`,
          params: {
            id: "scrollDown",
          },
        })
        .then(() => {
          this.path = `/${val}`;
        });
    },
  },
};
</script>

<template>
  <section class="container mx-auto">
    <img
      class="col-span-2 max-h-14 w-full object-contain lg:col-span-1"
      src="@/assets/images/69white.png"
      alt="Light Logo"
    />

    <p class="font-semibold text-primary-dark dark:text-primary-light m-12">
      RENO69 เว็บเดิมพันออนไลน์อันดับ 1 ที่มีประสิทธิภาพและความน่าเชื่อถือ
      จากผู้ให้บริการชั้นนำที่รวมไว้ที่นี้ เรามีครบทุกค่ายชั้นนำและครบวงจร ทั้ง
      บาคาร่า รูเร็ต สล็อตออนไลน์ กีฬาและหวยที่มีคนนิยมเล่นมากที่สุดในตอนนี้
      ทั้งไทยและต่างประเทศ และยังมีแอดมินคอยดูแล ฝาก-ถอนระบบAUTO 24 ชม
    </p>
    <a
      class="link-play text-sm text-black font-semibold py-2 px-12 border border-amber-400 hover:border-transparent rounded"
      href="https://reno69.com/account/register"
    >
      สมัครสมาชิก
    </a>

    <div class="py-12 sm:py-12">
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <dl
          class="grid grid-cols-1 gap-y-16 gap-x-8 text-center lg:grid-cols-3"
        >
          <div class="mx-auto flex max-w-xs flex-col gap-y-4">
            <img
              class="col-span-2 max-h-24 w-full object-contain lg:col-span-1"
              src="@/assets/images/button1.png"
              alt="Transistor"
            />
            <div>
              <dd
                class="bg-gold order-first text-3xl font-semibold tracking-tight text-white sm:text-3xl"
              >
                ฝาก-ถอน ออโต้
              </dd>
              <!-- <div class="border-glow" /> -->

              <dt class="text-base leading-7 text-white">
                รวดเร็ว มั่นใจ 100%
              </dt>
            </div>
          </div>

          <div class="mx-auto flex max-w-xs flex-col gap-y-4">
            <img
              class="col-span-2 max-h-24 w-full object-contain lg:col-span-1"
              src="@/assets/images/button2.png"
              alt="Transistor"
            />
            <div>
              <dd
                class="bg-gold order-first text-3xl font-semibold tracking-tight text-white sm:text-3xl"
              >
                บริการลูกค้า
              </dd>
              <!-- <div class="border-glow" /> -->
              <dt class="text-base leading-7 text-white">
                พร้อมดูแลตลออด 24 ชั่วโมง
              </dt>
            </div>
          </div>

          <div class="mx-auto flex max-w-xs flex-col gap-y-4">
            <img
              class="col-span-2 max-h-24 w-full object-contain lg:col-span-1"
              src="@/assets/images/button3.png"
              alt="Transistor"
            />
            <div>
              <dd
                class="bg-gold order-first text-3xl font-semibold tracking-tight text-white sm:text-3xl"
              >
                ความมั่นคง
              </dd>
              <!-- <div class="border-glow" /> -->
              <dt class="text-base leading-7 text-white">
                ระบบมาตรฐานสากลเท่าไหร่ก็จ่าย
              </dt>
            </div>
          </div>
        </dl>
      </div>
    </div>
  </section>

  <div class="bg-tabs mb-6">
    <section class="mx-auto my-auto">
      <dl
        class="mx-auto grid max-w-lg grid-cols-3 items-center gap-y-2 sm:max-w-xl sm:grid-cols-3 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-3"
      >
        <a href="/">
          <img
            class="w-full max-h-12 sm:pr-4 sm:pl-4 lg:pr-14 lg:pl-14 object-contain lg:col-span-1"
            src="@/assets/images/69white.png"
            alt="Light Logo"
        /></a>

        <div class="mx-auto flex max-w-xs flex-col gap-y-4">
          <!-- <dt class="text-base leading-7 text-white">รวดเร็ว มั่นใจ 100%</dt> -->
          <a
            :class="[
              'order-first text-white',
              path === '/guide' ? 'active' : '',
            ]"
            href="/guide"
            >แนะนำใช้งาน</a
          >
        </div>

        <div class="mx-auto flex max-w-xs flex-col gap-y-4">
          <!-- <dt class="text-base leading-7 text-white">
            พร้อมดูแลตลออด 24 ชั่วโมง
          </dt> -->

          <a
            :class="[
              'order-first text-white',
              path === '/promotion' ? 'active' : '',
            ]"
            href="/promotion"
            >โปรโมชั่น</a
          >
        </div>
      </dl>
    </section>
  </div>
</template>

<style scoped>
.border-glow {
  height: 2px;
  margin: 5px auto;
  background: linear-gradient(
    90deg,
    transparent,
    #ecbd7b 30%,
    #ecbd7b 70%,
    transparent
  );

  width: 70%;
  transition: all 0.6s;
}

.link-play {
  background-image: linear-gradient(#ca9541, #f8d88b, #ca9541);
  text-align: center;

  border-radius: 50px;
  border: 2px solid #ffe7c2;
  transition: all 0.6s;
  animation: anim-shadow infinite 4s;
}

.link-play:hover {
  color: #ffffff;
}

.bg-tabs {
  border-top: 2px solid #ecbd7b;
  border-bottom: 2px solid #ecbd7b;
  background: linear-gradient(
    90deg,
    #ecbd7b3f,
    #ecbd7b97,
    #ecbd7b97,
    #ecbd7b3f
  );
  padding: 15px 0px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
a {
  cursor: pointer;
}
a.active,
a:focus,
a:hover {
  background-color: #fff0;
  color: #fbc672;
}

/* @keyframes anim-border {
  0% {
    width: 200px;
  }

  100% {
    width: 400px;
  }
} */

@keyframes anim-shadow {
  0% {
    box-shadow: 0 0 2px #fff, 0 0 5px #e5bb58, 0 0 7px #e5bb58, 0 0 10px;
  }
  50% {
    box-shadow: 0 0 5px #fff, 0 0 13px #e5bb58, 0 0 21px #e5bb58,
      0 0 29px #e5bb58;
  }
  100% {
    box-shadow: 0 0 2px #fff, 0 0 5px #e5bb58, 0 0 7px #e5bb58, 0 0 10px;
  }
}
</style>
